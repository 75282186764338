import { AnyAction } from 'redux'
import {
  asyncDispatchFactory,
  StoreReduxContext,
  useStoreHooksFromReduxContext,
} from '~lib/reducer'

import * as QSUserActions from './actions'
import { User } from './model'
import { reducer as QSUser } from './reducer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQSUserHooks = (context: StoreReduxContext<any, AnyAction>) => {
  const { useSelector, useDispatch } = useStoreHooksFromReduxContext(context)

  const dispatch = useDispatch()
  const asyncDispatch = asyncDispatchFactory(dispatch)

  const users = useSelector(QSUser.selectors.selectUsersList) as User[]

  const fetchUsersList = async () => await asyncDispatch(QSUserActions.fetchListUsersAction, {})

  return {
    users,
    fetchUsersList,
  }
}
