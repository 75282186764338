import { Amplify, Auth } from 'aws-amplify'
import { ConfigurationFunctionTemplate, ConfigurationResponse } from '~lib/configuration'

type AmplifyConfigurationResponse = {
  amplifyConfiguration: any
  amplifyAuthConfiguration: any
}

function normalizeAPIURL(url: string) {
  if (url.endsWith('/')) {
    return url.substring(0, url.length - 1);
  }
  return url;
}

export const configure: ConfigurationFunctionTemplate<
  ConfigurationResponse,
  AmplifyConfigurationResponse
> = () => {
  const amplifyConfiguration = Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      mandatorySignIn: true,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },

    API: {
      endpoints: [
        {
          name: 'API',
          endpoint: `${normalizeAPIURL(process.env.REACT_APP_API_DOMAIN as string)}/quicksight`,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
          },
        },
      ],
    },
  })

  const amplifyAuthConfiguration = Auth.configure()

  return {
    amplifyConfiguration,
    amplifyAuthConfiguration,
  }
}
