import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ICredentials } from '@aws-amplify/core'
import { toPromise } from '~lib/reducer'

import {
  SET_USERS_LIST,
  FETCH_USERS_LIST,
  SET_USER_POOL_ID,
  SET_USER_POOL_REGION,
} from './constants'

export const setUserPoolIdAction = createAction(SET_USER_POOL_ID, (userPoolId: string) => ({
  payload: {
    userPoolId,
  },
}))

export const setUserPoolRegionAction = createAction(
  SET_USER_POOL_REGION,
  (userPoolRegion: string) => ({
    payload: {
      userPoolRegion,
    },
  }),
)

export const fetchUsersListAction = createAsyncThunk(
  FETCH_USERS_LIST,
  async (params: {
    userPoolId: string
    userPoolRegion: string
    user: any
    credentials: ICredentials
  }) =>
    await toPromise<CognitoIdentityServiceProvider.Types.ListUsersResponse>((callback) => {
      const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
        region: params.userPoolRegion,
        credentials: params.credentials,
      })
      return cognitoIdentityServiceProvider.listUsers(
        {
          UserPoolId: params.userPoolId,
        },
        callback,
      )
    }),
)

export const setUsersListAction = createAction(
  SET_USERS_LIST,
  (usersList: CognitoIdentityServiceProvider.Types.ListUsersResponse) => ({
    payload: {
      usersList,
    },
  }),
)
