import { generateReducerSelector, GenericReducerSelectorFunctionGenerator } from '~lib/reducer'

import { ListDashboardsReducerState } from './model'

export const selectLoadingDashboards: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: ListDashboardsReducerState) => state.loadingDashboards])

  export const selectDashboardList: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: ListDashboardsReducerState) => state.dashboards])

export const selectDashboardURL: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: ListDashboardsReducerState) => state.dashboardURL])
