import { AnyAction } from 'redux'
import {
  asyncDispatchFactory,
  StoreReduxContext,
  useStoreHooksFromReduxContext,
} from '~lib/reducer'

import * as QSGroupActions from './actions'
import { Group } from './model'
import { reducer as QSGroup } from './reducer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQSGroupHooks = (context: StoreReduxContext<any, AnyAction>) => {
  const { useSelector, useDispatch } = useStoreHooksFromReduxContext(context)

  const dispatch = useDispatch()
  const asyncDispatch = asyncDispatchFactory(dispatch)

  const groups = useSelector(QSGroup.selectors.selectGroupsList) as Group[]

  const fetchGroupList = async (params: QSGroupActions.ListGroupParams = {}) =>
    await asyncDispatch(QSGroupActions.fetchListGroupsAction, params)

  return {
    groups,

    fetchGroupList,
  }
}
