import React from 'react'
import { RoutesConfiguration } from '~lib/router'

export const configureRoutes: RoutesConfiguration = () => {
  return [
    {
      path: '/login',
      component: React.lazy(() =>
        import('../components/Pages/Login').then((module) => ({
          default: module.Login,
        })),
      ),
    },
    {
      path: '/recover-password',
      component: React.lazy(() =>
        import('../components/Pages/RecoverPassword').then((module) => ({
          default: module.RecoverPassword,
        })),
      ),
    },
    {
      path: '/user/home',
      component: React.lazy(() =>
        import('../components/Pages/User/Home').then((module) => ({
          default: module.UserHome,
        })),
      ),
      restrictionType: 'user',
      onFailedActivation: () => '/login',
    },
    {
      path: '/user/dashboard',
      component: React.lazy(() =>
        import('../components/Pages/User/Dashboard').then((module) => ({
          default: module.UserDashboard,
        })),
      ),
      restrictionType: 'user',
      onFailedActivation: () => '/login',
    },
  ]
}
