import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import {
  FETCH_GROUP_LIST,
  SET_GROUP_LIST,
} from './constants'
import { API } from '@aws-amplify/api'
import { Group } from './model'

export enum CompareOperator {
  LOWER_THAN = 'LOWER_THAN',
  EQUAL_AND_LOWER_THAN = 'EQUAL_AND_LOWER_THAN',
  EQUAL_TO = 'EQUAL_TO',
  EQUAL_AND_UPPER_THAN = 'EQUAL_AND_UPPER_THAN',
  UPPER_THAN = 'UPPER_THAN',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  ALL = 'ALL',
}

export type ListGroupParams = {
  priority?: number
  filter?: CompareOperator
}

const listGroups = (params: ListGroupParams = {}) =>
  API.get('API', '/group', {
    queryStringParameters: params,
  })

export const fetchListGroupsAction = createAsyncThunk(
  FETCH_GROUP_LIST,
  async (params: ListGroupParams = {}) => listGroups(params),
)

export const setListGroupsAction = createAction(SET_GROUP_LIST, (groups: Group[]) => ({
  payload: {
    groups,
  },
}))
