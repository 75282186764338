import { generateReducer, ReducerSelectorFunctions } from '~lib/reducer'

import { User, ListUsersReducerState } from './model'
import * as actions from './actions'
import * as selectors from './selectors'

const initialState: ListUsersReducerState = {
  users: [],
}

const setUserList = (state: ListUsersReducerState, users: User[]) => ({
  ...state,
  users: users.filter((user) => user.Role === 'READER'),
})

export const reducer = generateReducer(
  {
    name: 'qs_user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(actions.setListUsersAction, (state, action) =>
          setUserList(state, action.payload.users),
        )
        .addCase(actions.fetchListUsersAction.fulfilled, (state, action) =>
          setUserList(state, action.payload),
        )
    },
  },
  selectors as ReducerSelectorFunctions,
)
