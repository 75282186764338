import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { generateReducer, ReducerSelectorFunctions } from '~lib/reducer'

import { UsersListReducerState } from './model'
import * as actions from './actions'
import * as selectors from './selectors'

const initialState: UsersListReducerState = {
  userPoolId: null,
  userPoolRegion: null,

  listUsers: {},
}

const setUserPoolId = (state: UsersListReducerState, userPoolId: string) => ({
  ...state,
  userPoolId,
})

const setUserPoolRegion = (state: UsersListReducerState, userPoolRegion: string) => ({
  ...state,
  userPoolRegion,
})

const setUsersList = (
  state: UsersListReducerState,
  listUsers: CognitoIdentityServiceProvider.Types.ListUsersResponse,
) => ({
  ...state,
  listUsers,
})

export const reducer = generateReducer(
  {
    name: 'cognito',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(actions.setUserPoolIdAction, (state, action) =>
          setUserPoolId(state, action.payload.userPoolId),
        )
        .addCase(actions.setUserPoolRegionAction, (state, action) =>
          setUserPoolRegion(state, action.payload.userPoolRegion),
        )
        .addCase(actions.setUsersListAction, (state, action) =>
          setUsersList(state, action.payload.usersList),
        )
        .addCase(actions.fetchUsersListAction.fulfilled, (state, action) =>
          setUsersList(state, action.payload),
        )
    },
  },
  selectors as ReducerSelectorFunctions,
)
