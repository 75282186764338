import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { useCallback } from 'react'
import { AnyAction } from 'redux'
import { useUserHooks } from '~containers/User'

import {
  asyncDispatchFactory,
  StoreReduxContext,
  useStoreHooksFromReduxContext,
} from '~lib/reducer'

import * as CognitoActions from './actions'
import { reducer as Cognito } from './reducer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCognitoHooks = (context: StoreReduxContext<any, AnyAction>) => {
  const { useSelector, useDispatch } = useStoreHooksFromReduxContext(context)

  const dispatch = useDispatch()
  const asyncDispatch = asyncDispatchFactory(dispatch)

  const userPoolId = useSelector(Cognito.selectors.selectUserPoolId)
  const userPoolRegion = useSelector(Cognito.selectors.selectUserPoolRegion)
  const usersList = useSelector(
    Cognito.selectors.selectUsersList,
  ) as CognitoIdentityServiceProvider.Types.ListUsersResponse

  const { user, credentials } = useUserHooks(context)

  const fetchUsersList = async () =>
    await asyncDispatch(CognitoActions.fetchUsersListAction, {
      userPoolId,
      userPoolRegion,
      user,
      credentials,
    })

  const setUserPoolId = useCallback(
    (userPoolId: string) => dispatch(CognitoActions.setUserPoolIdAction(userPoolId)),
    [dispatch],
  )
  const setUserPoolRegion = useCallback(
    (userPoolRegion: string) => dispatch(CognitoActions.setUserPoolRegionAction(userPoolRegion)),
    [dispatch],
  )

  return {
    userPoolId,
    userPoolRegion,
    usersList,

    setUserPoolId,
    setUserPoolRegion,
    fetchUsersList,
  }
}
