import { generateReducer, ReducerSelectorFunctions } from '~lib/reducer'

import { Group, ListGroupsReducerState } from './model'
import * as actions from './actions'
import * as selectors from './selectors'

const initialState: ListGroupsReducerState = {
  groups: [],
}

const setGroupList = (state: ListGroupsReducerState, groups: Group[]) => ({
  ...state,
  groups,
})

export const reducer = generateReducer(
  {
    name: 'qs_group',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(actions.setListGroupsAction, (state, action) =>
          setGroupList(state, action.payload.groups),
        )
        .addCase(actions.fetchListGroupsAction.fulfilled, (state, action) =>
          setGroupList(state, action.payload),
        )
    },
  },
  selectors as ReducerSelectorFunctions,
)
