import { generateReducerSelector, GenericReducerSelectorFunctionGenerator } from '~lib/reducer'
import { UsersListReducerState } from './model'

export const selectUserPoolId: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: UsersListReducerState) => state.userPoolId])

export const selectUserPoolRegion: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: UsersListReducerState) => state.userPoolRegion])

export const selectUsersList: GenericReducerSelectorFunctionGenerator = (storeKey: string) =>
  generateReducerSelector(storeKey, [(state: UsersListReducerState) => state.listUsers])
