import { generateReducer, ReducerSelectorFunctions } from '~lib/reducer'

import { Dashboard, DashboardURL, ListDashboardsReducerState } from './model'
import * as actions from './actions'
import * as selectors from './selectors'

const initialState: ListDashboardsReducerState = {
  dashboards: [],
  dashboardURL: null,
  loadingDashboards: false,
}

const setDashboardList = (state: ListDashboardsReducerState, dashboards: Dashboard[]) => ({
  ...state,
  dashboards,
  loadingDashboards: false,
})

const setDashboardURL = (state: ListDashboardsReducerState, dashboardURL: DashboardURL | null) => {
  return {
    ...state,
    dashboardURL,
  }
}

const setLoadingDashboard = (state: ListDashboardsReducerState) => {
  return {
    ...state,
    loadingDashboards: true,
  }
}

export const reducer = generateReducer(
  {
    name: 'qs_dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(actions.setListDashboardsAction, (state, action) =>
          setDashboardList(state, action.payload.dashboards),
        )
        .addCase(actions.setDashboardURLAction, (state, action) =>
          setDashboardURL(state, action.payload.dashboardURL),
        )
        .addCase(actions.fetchListDashboardsAction.fulfilled, (state, action) =>
          setDashboardList(state, action.payload),
        )
        .addCase(actions.fetchDashboardUrlAction.fulfilled, (state, action) =>
          setDashboardURL(state, action.payload),
        )
        .addCase(actions.loadDashboardsAction, (state) =>
          setLoadingDashboard(state)
        )
    },
  },
  selectors as ReducerSelectorFunctions,
)
