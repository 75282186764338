import { useCallback } from 'react'
import { AnyAction } from 'redux'

import {
  asyncDispatchFactory,
  StoreReduxContext,
  useStoreHooksFromReduxContext,
} from '~lib/reducer'

import * as QSDashboardActions from './actions'
import { Dashboard, DashboardURL } from './model'
import { reducer as QSDashboard } from './reducer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQSDashboardHooks = (context: StoreReduxContext<any, AnyAction>) => {
  const { useSelector, useDispatch } = useStoreHooksFromReduxContext(context)

  const dispatch = useDispatch()
  const asyncDispatch = asyncDispatchFactory(dispatch)

  const dashboards = useSelector(QSDashboard.selectors.selectDashboardList) as Dashboard[]
  const dashboardURL = useSelector(QSDashboard.selectors.selectDashboardURL) as DashboardURL
  const loadingDashboards = useSelector(QSDashboard.selectors.selectLoadingDashboards) as boolean

  const fetchDashboardList = async () =>
    await asyncDispatch(QSDashboardActions.fetchListDashboardsAction, {})

  const fetchDashboardUrl = async (dashboard: Dashboard) =>
    (await asyncDispatch(QSDashboardActions.fetchDashboardUrlAction, {
      dashboard,
    })) as Promise<DashboardURL>

  const clearDashboardURL = useCallback(
    () => dispatch(QSDashboardActions.setDashboardURLAction(null)),
    [dispatch],
  )

  const loadDashboards = useCallback(
    () => dispatch(QSDashboardActions.loadDashboardsAction()),
    [dispatch],
  )

  return {
    dashboards,
    dashboardURL,
    loadingDashboards,

    fetchDashboardList,
    fetchDashboardUrl,

    clearDashboardURL,
    loadDashboards,
  }
}
