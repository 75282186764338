import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { useReduxContext, useStoreHooksFromReduxContext } from '~lib/reducer'
import { AppRouter, useNavigation } from '~lib/router'

import { Cognito } from '~containers/Cognito'
import { useCognitoHooks } from '~containers/Cognito'

import { User, UserProtectedRoute, useUserHooks } from '~containers/User'
import { QSUser } from '~containers/QSUser'
import { QSGroup } from '~containers/QSGroup'
import { QSDashboard } from '~containers/QSDashboard'

import { configure } from '../config'

library.add(fas)
library.add(far)
library.add(fab)

const reducers = {
  [User.name]: User.reducer,
  [Cognito.name]: Cognito.reducer,
  [QSUser.name]: QSUser.reducer,
  [QSGroup.name]: QSGroup.reducer,
  [QSDashboard.name]: QSDashboard.reducer,
}

export const App: React.FunctionComponent = () => {
  const appContext = useReduxContext(reducers)
  const { context, useStore } = useStoreHooksFromReduxContext(appContext)
  const store = useStore()

  // User managemente
  const { user, fetchCurrentUser, fetchCurrentCredentials } = useUserHooks(appContext)
  const { setUserPoolId, setUserPoolRegion } = useCognitoHooks(appContext)
  const { pushPage } = useNavigation(appContext)

  const [isRequested, setRequested] = useState(false)

  // Load configuration
  const [routes, setRoutes] = useState([])
  useEffect(() => {
    const fetchConfiguration = async () => {
      const { routes: configuredRoutes, app } = await configure()
      setRoutes(configuredRoutes)
      setUserPoolId(app.aws.userPoolId)
      setUserPoolRegion(app.aws.region)
    }
    fetchConfiguration()
  }, [])

  useEffect(() => {
    if (!isRequested) {
      fetchCurrentUser().then(() => fetchCurrentCredentials())
      setRequested(true)
    } else if (!user) {
      pushPage('/login')
    }
  }, [isRequested, user])

  // Render
  return (
    <Provider context={context} store={store}>
      {routes && (
        <AppRouter
          context={context}
          routes={routes}
          protectedComponents={{
            user: UserProtectedRoute,
          }}
        />
      )}
    </Provider>
  )
}
