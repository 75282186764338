import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import {
  FETCH_DASHBOARD_LIST,
  SET_DASHBOARD_LIST,
  FETCH_DASHBOARD_URL,
  SET_DASHBOARD_URL,
  LOAD_DASHBOARDS,
} from './constants'
import { API } from '@aws-amplify/api'
import { Dashboard, DashboardURL } from './model'
import { Group } from '~containers/QSGroup'

export type DashboardStruct = {
  dashboard: Dashboard
}

export type DashboardGroupStruct = {
  group: Group
} & DashboardStruct

export type DashboardDescriptionStruct = {
  description: string
} & DashboardStruct

const listDashboards = async (params: any = {}) => {
  const response = await API.get('API', '/dashboard', {
    queryStringParameters: params,
  })
  return response
}

const getDashboardUrl = (dashboard: Dashboard) =>
  API.get('API', '/dashboard/url', {
    queryStringParameters: {
      dashboardId: dashboard.DashboardId,
    },
  }) as Promise<DashboardURL>

export const fetchListDashboardsAction = createAsyncThunk(
  FETCH_DASHBOARD_LIST,
  async (params: any = {}) => listDashboards(params),
)

export const fetchDashboardUrlAction = createAsyncThunk(
  FETCH_DASHBOARD_URL,
  async (params: DashboardStruct) => getDashboardUrl(params.dashboard),
)

export const setListDashboardsAction = createAction(
  SET_DASHBOARD_LIST,
  (dashboards: Dashboard[]) => ({
    payload: {
      dashboards,
    },
  }),
)

export const setDashboardURLAction = createAction(
  SET_DASHBOARD_URL,
  (dashboardURL: DashboardURL | null) => ({
    payload: {
      dashboardURL,
    },
  }),
)

export const loadDashboardsAction = createAction(LOAD_DASHBOARDS, () => ({
  payload: {
    loadingDashboards: true,
  },
}))
