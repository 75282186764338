import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import {
  FETCH_USER_LIST,
  SET_USER_LIST,
} from './constants'
import { API } from '@aws-amplify/api'
import { User } from './model'
import { Group } from '~containers/QSGroup'

export type UserStruct = {
  user: User
}

export type UserGroupStruct = UserStruct & {
  group: Group
}

const listUsers = (params: any = {}) =>
  API.get('API', '/user', {
    queryStringParameters: params,
  })

export const fetchListUsersAction = createAsyncThunk(FETCH_USER_LIST, async (params: any = {}) =>
  listUsers(params),
)

export const setListUsersAction = createAction(SET_USER_LIST, (users: User[]) => ({
  payload: {
    users,
  },
}))
